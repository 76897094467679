import React from 'react';

import './loading.svg.css';

const LoadingSvg = (): React.ReactElement => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="spinner-autocapture"
    >
      {/* Animation logic is moved to external CSS */}
      <g className="spinner-autocapture">
        <circle
          cx="34"
          cy="34"
          fill="none"
          r="30"
          strokeWidth="4"
          stroke="#000"
          strokeDasharray="51 12"
          strokeLinecap="round"
          strokeDashoffset="0"
        />
      </g>
    </svg>
  );
};

export default LoadingSvg;
