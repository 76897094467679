import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useLogger } from '../../hooks/use-logger';
import { useFlowContext } from '../../hooks/verification-flow';
import { routes } from '../../routes/routes.utils';
import { registerServiceWorker } from '../../service-worker/service-worker-registration';
import { StatusRes, VerificationRecaptureReason } from '../../types/verification-status-types';
import { httpGet } from '../../utils/http';
import { eventFactory } from '../../utils/monitoring/event-factory';
import { addStatus, isRedirected } from '../../utils/monitoring/init-ui-monitoring';
import { sendEvent } from '../../utils/monitoring/send-bi';
import { addTag, captureException } from '../../utils/monitoring/sentry';
import { objectToUrlSearchParams } from '../../utils/url-format/url-format';

// SDK interfaces
type ErrorCodes =
  | 'other'
  | 'glare'
  | 'document_not_found'
  | 'blur'
  | 'document_not_supported'
  | 'obstructed'
  | 'document_not_matching'
  | 'face_not_found'
  | 'wrong_document_side'
  | 'glare_selfie'
  | 'blur_selfie'
  | 'multi_face'
  | 'face_rotated'
  | 'face_too_small'
  | 'face_too_close'
  | 'closed_eyes'
  | 'face_angle_too_large'
  | 'face_close_to_border'
  | 'face_occluded'
  | 'face_cropped'
  | 'barcode_not_found';
type ImageFeedback = ErrorCodes | 'ok';
type Errors = ErrorCodes | 'camera-error' | 'camera-permission-dismissed' | 'camera-permission-denied';
type Step =
  | 'document_front'
  | 'document_back'
  | 'selfie'
  | 'processing'
  | 'consent'
  | 'error'
  | 'complete'
  | 'recapture'
  | 'start';
type VerificationStatus = 'pending' | 'capturing' | 'processing' | 'recapture' | 'complete' | 'error';
type ImageType = 'document_front' | 'document_back' | 'selfie';
interface SdkEventData {
  type:
    | 'page-load'
    | 'init'
    | 'session-id-acquired'
    | 'capture-image'
    | 'capture-image-result'
    | 'start-verification-processing'
    | 'verification-result'
    | 'capture-error';
  step: Step;
  status?: VerificationStatus;
  imageType?: ImageType;
  sessionId?: string;
  feedback?: ImageFeedback;
  errorCode?: string;
  state?: string;
  callbackUrl?: string;
}
interface sessionData {
  session: string;
}
export interface CallbackMethods {
  sessionGranted?: (sessionMetadata: sessionData) => void;
  sessionStarted: (sessionMetadata: sessionData) => void;
  imageSubmitted: (sessionMetadata: sessionData, type: ImageType) => void;
  processing: (sessionMetadata: sessionData) => void;
  completed: (sessionMetadata: sessionData, callbackUrl: string) => void;
  recapture: (sessionMetadata: sessionData, reason: VerificationRecaptureReason) => void;
  error: (sessionMetadata: sessionData, error: Errors, errorCode: string) => void;
}
interface SdkInit {
  clientId: string;
  IDV: {
    serverPath: string;
    callbacks: CallbackMethods;
    callback: (event: SdkEventData) => void;
    consentVersion?: string;
    language?: string;
    startToken?: string;
    markdownSupportText?: string;
    videoCaptureSettings?: object;
    rootElement?: string;
    cdnHost?: string;
    isImageSigningEnabled?: boolean;
  };
  drs?: { serverPath: string; enableSessionToken: boolean };
}
// SDK interfaces

const IdvSdkComponent: React.FC<{ startToken: string }> = ({ startToken }) => {
  const { logMessage } = useLogger('');
  const navigate = useNavigate();
  const location = useLocation();
  const [token] = useState(startToken);
  const { state, dispatch } = useFlowContext();

  const shouldSeeSdk = useMemo(() => {
    return location.pathname.includes(routes.sdkFlow.path);
  }, [location]);

  const getStatus = async (sessionId: string) => {
    const res = await httpGet<StatusRes>(`verify/api/v1/verification/${sessionId}/status`);
    return { callbackUrl: res.data.callback_url, stateVal: res.data.state };
  };

  const handleVerificationResponse = async (sessionId: string) => {
    const { callbackUrl, stateVal } = await getStatus(sessionId);
    if (state.isStartedOnDesktop) {
      navigate(`/${token}/${routes.mobileVerificationComplete.path}`);
    } else {
      const urlParameters =
        '?' + objectToUrlSearchParams({ sessionId: sessionId || '', state: stateVal || '' }).toString();
      const redirectUrl = callbackUrl ? callbackUrl + urlParameters : '/' + urlParameters;

      if (redirectUrl.startsWith('http://') || redirectUrl.startsWith('https://')) {
        isRedirected('true');
        const eventReport = eventFactory.createRedirectEvent(redirectUrl, 'processing');
        sendEvent(sessionId, eventReport);
        sessionStorage.setItem('redirectUrl', redirectUrl);
        sessionStorage.setItem('sessionId', sessionId);
        sessionStorage.setItem('status', 'complete');
        window.location.href = redirectUrl;
      } else {
        dispatch({
          ...state,
          redirectUrl: redirectUrl,
        });
        navigate(`/session/${sessionId}/${routes.clickToContinue.path}`);
      }
    }
  };
  const handleSessionGranted = useCallback(({ session }) => {
    logMessage('session has granted');
    dispatch({
      ...state,
      sessionId: session,
    });
  }, []);
  const handleSessionStarted = useCallback(({ session }) => {
    logMessage('session has started');
    // TODO: delete this once autocapture merged to main branch in sdk
    dispatch({
      ...state,
      sessionId: session,
    });
    navigate(`/session/${session}/${routes.sdkFlow.path}`);
  }, []);
  const handleImageSubmitted = useCallback(({ session }: sessionData, type: ImageType) => {
    logMessage(`image has been submitted; type: ${type}; session: ${session}`);
  }, []);
  const handleProcessing = useCallback(({ session }: sessionData) => {
    logMessage('processing has started');
    navigate(`/session/${session}/${routes.sdkProcessing.path}`);
  }, []);
  const handleCompleted = useCallback(({ session }) => {
    logMessage('verification has been completed');
    addStatus('complete');
    handleVerificationResponse(session);
  }, []);
  const handleRecapture = useCallback(({ session }: sessionData, reason: string) => {
    dispatch({
      ...state,
      recaptureReason: reason,
    });
    logMessage('recapture has been requested');
    addStatus('recapture');
    navigate(`/session/${session}/${routes.retry.path}`);
  }, []);
  const handleError = useCallback(({ session }: sessionData, error: Errors, errorCode: string) => {
    const sdkVersion = window.tsPlatform.idv.version();
    addTag('web-sdk-version', sdkVersion || 'unknown');
    addTag('web-sdk', 'true');
    captureException(new Error(error), { sessionId: session });
    if (['camera-permission-denied', 'camera-permission-dismissed'].includes(error)) {
      navigate(`/session/${session}/${routes.sdkCameraError.path}`, { state: { type: error } });
    }
    if (['request-error'].includes(error)) {
      if (errorCode === 'Forbidden') {
        navigate(`/session/${session}/${routes.timeout.path}`, {
          state: { type: error, errorType: 'sessionTimeout' },
        });
      } else {
        navigate(`/session/${session}/${routes.error.path}`, { state: { type: error, errorType: 'serverError' } });
      }
    }
    if (['session-expired'].includes(error)) {
      navigate(`/session/${session}/${routes.timeout.path}`, {
        state: { type: error, errorType: 'sessionExpired' },
      });
    }
  }, []);

  useEffect(() => {
    const sdkInit: SdkInit = {
      clientId: state.clientId,
      IDV: {
        consentVersion: state.commitHash,
        serverPath: window.location.origin + '/verify',
        language: state.appSettings.lng,
        startToken: state.startToken,
        markdownSupportText: state.appSettings.markdownSupportText,
        videoCaptureSettings: state.appSettings.videoCaptureSettings,
        rootElement: 'idv-root',
        cdnHost: state.appSettings.cdnHost,
        isImageSigningEnabled: state.appSettings.isImageSigningEnabled,
        callbacks: {
          sessionGranted: handleSessionGranted,
          sessionStarted: handleSessionStarted,
          imageSubmitted: handleImageSubmitted,
          processing: handleProcessing,
          completed: handleCompleted,
          recapture: handleRecapture,
          error: handleError,
        },
        callback: () => {
          //ignore callback
        },
      },
    };
    if (state.appSettings.drsServerPath) {
      sdkInit.drs = { serverPath: state.appSettings.drsServerPath, enableSessionToken: true };
    }

    if (state.appSettings.autoCaptureEnabled) {
      registerServiceWorker(state.startToken, state.clientId, state.appSettings.isDebuggerEnabled ?? false);
    }

    try {
      window.tsPlatform.initialize(sdkInit);
      dispatch({
        ...state,
        isWebSdkInitialized: true,
      });
      console.log('Transmit Security SDK initialized successfully');
    } catch (e) {
      console.error(e);
      console.error('Transmit Security SDK failed to initialize');
    }
  }, []);

  return (
    <>
      <div
        id="idv-root"
        className="main-layout"
        style={{
          height: (shouldSeeSdk && '100%') || '0px',
          visibility: (shouldSeeSdk && 'visible') || 'hidden',
          display: (shouldSeeSdk && 'block') || 'none',
        }}
      ></div>
    </>
  );
};

export default IdvSdkComponent;
