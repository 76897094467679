import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import LoadingBlockComponent from '../../components/loading-block/loading-block.component';
import TenantLogoHeader from '../../components/logo-header/logo-header.component';
import PageComponent from '../../components/page/page.component';
import Title from '../../components/title/title.component';
import { ErrorType, useErrorNavigation } from '../../hooks/error-navigation';
import { useLogger } from '../../hooks/use-logger';
import { SessionStatus, useFlowContext } from '../../hooks/verification-flow';
import { routes } from '../../routes/routes.utils';
import { StatusRes } from '../../types/verification-status-types';
import { httpGet } from '../../utils/http';
import { eventFactory } from '../../utils/monitoring/event-factory';
import { addStatus, isRedirected } from '../../utils/monitoring/init-ui-monitoring';
import { sendEvent } from '../../utils/monitoring/send-bi';
import { captureException } from '../../utils/monitoring/sentry';
import { objectToUrlSearchParams } from '../../utils/url-format/url-format';

import './processing.page.scss';

const VERIFICATION_STATUS_POLL_INTERVAL = 3000;

const Processing: React.FC = function () {
  const { handleTimeout, handleError } = useErrorNavigation();
  const { logMessage } = useLogger('');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state, dispatch } = useFlowContext();
  const { sid = '' } = useParams();

  useEffect(() => {
    logMessage('processing has started');
  }, []);

  let checkStatusInterval: NodeJS.Timer;

  const handleVerificationResponse = async (res: StatusRes) => {
    clearInterval(checkStatusInterval);
    if (state.isStartedOnDesktop) {
      navigate(`/session/${sid}/${routes.mobileVerificationComplete.path}`);
    } else {
      const urlParameters = '?' + objectToUrlSearchParams({ sessionId: sid, state: res?.state || '' }).toString();
      const redirectUrl = res.callback_url ? res.callback_url + urlParameters : '/' + urlParameters;

      if (redirectUrl.startsWith('http://') || redirectUrl.startsWith('https://')) {
        const eventReport = eventFactory.createRedirectEvent(redirectUrl, 'processing');
        await sendEvent(sid, eventReport);
        isRedirected('true');
        window.location.href = redirectUrl;
      } else {
        dispatch({
          ...state,
          redirectUrl: redirectUrl,
        });
        navigate(`/session/${sid}/${routes.clickToContinue.path}`);
      }
    }
  };

  const parseResponse = (res: { data: StatusRes }) => {
    if (res?.data?.status) {
      state.status = res.data.status as SessionStatus;
      if (res.data.status == 'complete') {
        logMessage('verification has been completed');
        addStatus('complete');
        handleVerificationResponse(res.data);
      } else if (res?.data?.status === 'recapture') {
        logMessage('recapture has been requested');
        addStatus('recapture');
        dispatch({
          ...state,
          recaptureReason: res.data.recapture_reason,
          previousStep: 'PROCESSING',
          currentStep: 'RETRY',
        });
        navigate(`/session/${sid}/${routes.retry.path}`);
      }
    } else if (res?.data?.state !== 'processing') {
      if (res?.data?.state) addStatus(res?.data?.state);
      handleVerificationResponse(res.data);
    }
  };

  const pollVerificationStatus = () => {
    let attempt = 1;

    const getStatus = () => {
      httpGet<StatusRes>(`verify/api/v1/verification/${sid}/status`)
        .then(parseResponse)
        .catch((error) => {
          if (error?.message.includes('Forbidden')) {
            const additionalInfo = { sessionId: sid };
            captureException(new Error('Forbidden'), additionalInfo);
            handleTimeout(error as Error, state.currentStep, ErrorType.sessionTimeout, sid);
          } else {
            attempt++;
            //we want to retry up to 10 calls before we go to an error page
            if (attempt <= 10) {
              getStatus();
            } else {
              captureException(error as Error, { sessionId: sid, step: state.currentStep, ...error });
              handleError(error as Error, state.currentStep, sid);
            }
          }
        });
    };
    getStatus();
  };

  useEffect(() => {
    checkStatusInterval = setInterval(pollVerificationStatus, VERIFICATION_STATUS_POLL_INTERVAL);
    return () => {
      clearInterval(checkStatusInterval);
    };
  }, []);

  return (
    <PageComponent
      className={'processing-component'}
      title={
        <div className={'page-title'}>
          <TenantLogoHeader />
        </div>
      }
    >
      <Title className={'sub-title'} level={3}>
        {t('verification_in_progress')}
      </Title>
      <LoadingBlockComponent className={'centered'} />
      <Title className={'loading-message'} level={4}>
        {t('please_wait')}
      </Title>
    </PageComponent>
  );
};

export default Processing;
