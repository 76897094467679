import { createContext, Dispatch, useContext, useReducer } from 'react';
export type RectangleCoords = {
  top: number;
  bottom: number;
  left: number;
  right: number;
};

export type Image = {
  format: string;
  data: string;
  captureMargins: RectangleCoords | undefined;
};

export type CameraInfo = {
  label: string;
};

export interface VerificationStartResponse {
  settings: AppSettings;
  session_id: string;
  required_images: string[];
}

export interface AppSettings {
  theme?: string;
  lng?: string;
  logoUrl?: string;
  refineImages?: boolean;
  showAnalyzingText: boolean;
  showConsentMessage?: boolean;
  loaderType: string;
  isCameraDisabledOnInit: boolean;
  supportedDocumentTypes: string[];
  consentType?: string;
  customConsentText?: string;
  customAdditionalConsentText?: string;
  isSDKEnabled: boolean;
  sdkUrl: string;
  drsServerPath: string;
  isDebuggerEnabled: boolean;
  markdownSupportText?: string;
  redirectOnErrorUrl: string;
  videoCaptureSettings?: object;
  cdnHost?: string;
  isImageSigningEnabled?: boolean;
  autoCaptureEnabled?: boolean;
}

export type FlowActions =
  | 'DOCUMENT_FRONT'
  | 'DOCUMENT_BACK'
  | 'SELFIE'
  | 'ERROR'
  | 'PROCESSING'
  | 'ON_BOARDING'
  | 'RETRY'
  | 'CONSENT';
type ImageStatue = 'PENDING' | 'SUCCESS' | 'ERROR';
export type SessionStatus = 'pending' | 'capturing' | 'processing' | 'recapture' | 'complete' | 'error';
export interface VerificationAction {
  type: FlowActions;
  data?: Image;
  status?: ImageStatue;
  errorCode?: string;
  selectedCamera?: MediaDeviceInfo;
  optionalCameras?: MediaDeviceInfo[];
  startTime: number;
}

interface VerificationFlow {
  fullFlow: FlowActions[];
  actions: VerificationAction[];
  currentStep: FlowActions;
  previousStep: FlowActions;
  sessionId: string;
  startToken: string;
  requiredImages: string[];
  flowId: string;
  redirectUrl: string;
  state: string;
  tenantId: string;
  secretToken: string;
  appSettings: AppSettings;
  recaptureReason?: string;
  isStartedOnDesktop: boolean;
  missingImages?: Array<'document_front' | 'document_back' | 'selfie'>;
  status?: SessionStatus;
  commitHash?: string;
  isWebSdkInitialized: boolean;
  clientId: string;
  isDebuggerEnabled: boolean;
  qrScreenPollingInterval: number;
}
export type FlowContext = VerificationFlow;

const initialState = {} as FlowContext;

const FlowContext = createContext<{ state: FlowContext; dispatch: Dispatch<Partial<FlowContext>> } | undefined>(
  undefined,
);
const flowReducer = (state: FlowContext, newState: Partial<FlowContext>): FlowContext => {
  return {
    ...state,
    ...newState,
  };
};
// to be able to use a global/high level context we need to define that context. it could be globally (app root)
// or fragmented, the data will be divided into smaller pieces and will be provided for relevant parts in the application
const FlowContextProvider = ({ children, value }: { children: React.ReactNode; value?: FlowContext }) => {
  const [state, dispatch] = useReducer(flowReducer, value || initialState);
  return <FlowContext.Provider value={{ state, dispatch }}>{children}</FlowContext.Provider>;
};

const useFlowContext = () => {
  const context = useContext(FlowContext);
  if (context === undefined) {
    //if there is no value the hook is not being called within a function component that is rendered within a `FlowContextProvider`
    throw new Error('useFlowContext must be used within FlowContextProvider');
  }
  return context;
};

const addActionToContext = () => {
  const { state, dispatch } = useFlowContext();

  return {
    state,
    dispatch: (action: VerificationAction) => {
      const actionType = action.type;
      if (!state.actions) {
        state.actions = [];
      }
      const actionInStateIndex = state.actions.findIndex((a) => a.type === actionType);
      if (actionInStateIndex === -1) {
        state.actions.push(action);
      } else {
        state.actions[actionInStateIndex] = action;
      }
      dispatch(state);
    },
  };
};

export { addActionToContext, FlowContextProvider, useFlowContext };
