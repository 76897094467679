import React from 'react';

import { useFlowContext } from '../../hooks/verification-flow';

import Header from './header.component';

import './logo-header.component.scss';

interface TenantLogoHeader {
  className?: string;
}
const TenantLogoHeader: React.FC<TenantLogoHeader> = ({ className }) => {
  const { state } = useFlowContext();
  const logoUrl = getLogoUrl(state?.appSettings?.logoUrl);
  return <Header className={className} logoUrl={logoUrl} />;
};

// TODO: remove; see https://transmitsecurity.atlassian.net/browse/VER-1442
function getLogoUrl(tenantLogoUrl: string | undefined): string {
  if (!tenantLogoUrl) return '/verify/app/assets/transmit-logo.svg';

  if (tenantLogoUrl.startsWith('/assets/logos/')) {
    tenantLogoUrl = '/verify/app' + tenantLogoUrl;
  }

  return tenantLogoUrl;
}

export default TenantLogoHeader;
