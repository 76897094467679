import { eventFactory } from '../utils/monitoring/event-factory';
import { sendEvent } from '../utils/monitoring/send-bi';

const SERVICE_WORKER_PATH = '/verify/app/assets/service-worker.js';
const SERVICE_WORKER_SCOPE = '/verify/app/';

export async function registerServiceWorker(startToken: string, clientId: string, debug: boolean) {
  if ('serviceWorker' in navigator) {
    if (debug) console.log('Service Worker support detected');
    const existingRegistrations = await navigator.serviceWorker.getRegistrations();
    await Promise.all(
      existingRegistrations.map(async (registration) => {
        const success = await registration.unregister();
        if (debug) console.log('Unregistered existing service worker:', success);
      }),
    );
    // need to reload after unregistering the service worker so new worker will be activated
    if (navigator.serviceWorker.controller) {
      window.location.reload();
      return;
    }

    try {
      // Register immediately instead of waiting for load event
      const registration = await navigator.serviceWorker.register(
        `${SERVICE_WORKER_PATH}?debug=${debug === true}&startToken=${startToken}`,
        {
          scope: SERVICE_WORKER_SCOPE,
          updateViaCache: 'none',
        },
      );
      if (debug) console.log('ServiceWorker registration successful:', registration.scope);

      // Wait for the service worker to be activated
      await navigator.serviceWorker.ready;
      await registration.update();

      if (debug) console.log('sending message to service worker - app is loaded');
      registration.active?.postMessage({
        type: 'APP_LOADED',
        clientId,
        debug,
      });
    } catch (error) {
      const eventReport = eventFactory.createErrorEvent(
        'ServiceWorker registration failed',
        error?.toString() ?? 'Unknown error',
        'init',
      );
      sendEvent(startToken, eventReport);
      console.error('ServiceWorker registration failed:', error);
    }
  } else {
    console.warn('Service Worker support not detected');
  }
}
